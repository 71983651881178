<template>
  <div class="offer-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebar>
      <div class="menu">
        <div class="hidden-lg">
          <router-link
            active-class="active"
            class="sidebar-link navigation-link"
            tag="a"
            v-for="(section, i) in sectionsData"
            :to="{
              name: 'OfferView',
              params: { offerId: offer.uuid },
              hash: `#${section.hashAnchor}`,
            }"
            :key="i"
          >
            {{ section.menuLabel }}
          </router-link>
        </div>
      </div>
      <div class="social-links">
        <a :href="instagramUrl" v-if="instagramUrl">Instagram</a>
        <a :href="linkedinUrl" v-if="linkedinUrl">Linkedin</a>
        <a :href="facebookUrl" v-if="facebookUrl">Facebook</a>
      </div>
    </OrganismSidebar>
    <OrganismMenuBarOffer>
      <div class="hidden-xs">
        <router-link
          active-class="active"
          class="sidebar-link navigation-link"
          tag="a"
          v-for="(section, i) in sectionsData"
          :to="{
            name: 'OfferView',
            params: { offerId: offer.uuid },
            hash: `#${section.hashAnchor}`,
          }"
          :key="i"
        >
          {{ section.menuLabel }}
        </router-link>
      </div>
    </OrganismMenuBarOffer>
  </div>
</template>

<script>
import OrganismMenuBarOffer from "../components/organisms/OrganismMenuBarOffer";
import OrganismSidebar from "../components/organisms/OrganismSidebar";
export default {
  name: "OfferTemplate",
  components: { OrganismSidebar, OrganismMenuBarOffer },
  computed: {
    projects() {
      return this.$store.state.project.projects;
    },
    offerSections() {
      return this.$store.state.offer.sections;
    },
    sectionsData() {
      return this.enabledSections.filter((s) =>
        this.offerSections.includes(s.id)
      );
    },
    landingSections() {
      return this.$store.state.base.meta.landing.sections;
    },
    sections() {
      return this.$route.meta.page.sections;
    },
    enabledSections() {
      return this.$route.meta.page.sections.filter((e) => e.enabled);
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    activeSpace() {
      return this.$route.params.spaceId;
    },
    containsSpaceSlug() {
      return this.$route.params.spaceId !== undefined;
    },
    offer() {
      return this.$store.state.offer.offer;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  watch: {
    "$store.state.offer": {
      deep: true,
      handler: function () {},
    },
  },
  beforeUpdate() {
    this.removeEventListeners();
  },
  updated() {
    this.addEventListeners();
    if (this.$route.hash !== "") {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: "smooth",
      });
    }
  },
  methods: {
    addEventListeners() {
      const self = this;
      document
        .querySelectorAll('a.navigation-link[href*="#"]')
        .forEach((anchor) => {
          anchor.addEventListener("click", self.smoothScroll);
        });
    },
    removeEventListeners() {
      const self = this;
      document
        .querySelectorAll('a.navigation-link[href*="#"]')
        .forEach((anchor) => {
          anchor.removeEventListener("click", self.smoothScroll);
        });
    },
    smoothScroll(e) {
      e.preventDefault();
      document
        .querySelector(`#${e.target.getAttribute("href").split("#")[1]}`)
        .scrollIntoView({
          behavior: "smooth",
        });

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]');
      links.forEach((link) => {
        const activeLink = e.path[0];
        link.classList.remove("active");
        if (link === activeLink && !activeLink.classList.contains("active")) {
          link.classList.add("active");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-template {
  display: block;
}
</style>
