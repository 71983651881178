var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-menu-bar-offer", attrs: { id: "header-project" } },
    [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col col-xs-4 col-4" }, [
          _c(
            "div",
            { staticClass: "block-element" },
            [
              _c(
                "router-link",
                {
                  staticClass: "inline-elements",
                  attrs: { tag: "div", to: { name: "Home" } },
                },
                [_c("AtomLogo")],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col col-xs-8 col-8" }, [
          _c("div", { staticClass: "block-element text-right" }, [
            _c("div", { staticClass: "inline-elements" }, [
              _c("div", { staticClass: "menu" }, [_vm._t("default")], 2),
            ]),
            _c(
              "div",
              { staticClass: "inline-elements hidden-lg" },
              [_c("MoleculeMenuTrigger")],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }